import { Styled, jsx } from 'theme-ui';

/** @jsx jsx */
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';

export default function NotFound() {
  return (
    <Fragment>
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
      >
        <title>Not found - Dome GeoConsulting</title>
        <meta name="robots" content="noindex,nofollow,noarchive" />
      </Helmet>
      <Layout sx={{ maxWidth: '80%', margin: 'auto' }}>
        <section
          sx={{ width: ['90%', '90%', '80%'], margin: 'auto', mb: [1, 2, 4] }}
        >
          <Styled.h1>404 - Page not found.</Styled.h1>
          <Styled.p sx={{ textAlign: 'center' }}>
            Please use the navigation options above to access our content.
          </Styled.p>
        </section>
      </Layout>
    </Fragment>
  );
}
